/* For cell data alignment */
.align-right .ag-cell-wrapper {
  justify-content: flex-end;
}
.align-center .ag-cell-wrapper,
.align-center .ag-header-cell-label{
  justify-content: center;
}

/* For editable and non-editable cell */
.editable:hover {
  background-color: rgba(135, 207, 235, 0.5);
}
.non-editable:hover {
  background-color: #e7e7e7;
}

/* For checkboxes */
.ag-invisible {
  display: none !important;
}

/* For pagination on top-right */
.ag-paging-panel > span {
  color: black !important;
}
.ag-root-wrapper {
  position: relative !important;
}

/* AG Grid Table Wrapper customization */
.ag-root-wrapper-body {
  box-shadow: #e7e7e7;
  border-radius: 0.475rem;
}
.ag-theme-alpine .ag-root-wrapper {
  border: solid 1px;
  border-color: rgba(76, 87, 125, 0.1);
  border-radius: 0.475rem;
  box-shadow: 0px 0px 20px 0px rgb(76 87 125 / 2%);
}
/* AG Grid Table Wrapper customization ends */
.ag-paging-panel {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  border-top: none !important;
  height: 60px !important;
}

/* For page-size and buttons */
#ag-grid-container {
  position: relative;
}
.d3-tools {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 100;
}

/* For hiding arrows of number input */
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}