.mainContainer {
  /* max-width: 200px; */
  width: 150px;
  min-width: 150px;
  padding: 0.7rem;
}

.subContainer {
  padding: 2rem;
  min-height: 60vh;
  min-width: 40vw;
}

.subtitle {
  margin: 1rem;
  text-align: center;
}